import { AgendaOpenSummary } from "../types";
import { TakeVote } from "./TakeVote";
import { TakeUnvote } from "./TakeUnvote";
import { agendaApi } from "../redux/agendaApi";
import { useState } from "react";

export const AgendaOpenView: React.FC<{ agenda: AgendaOpenSummary }> = ({ agenda }) => {
  const { data: voteItem, refetch } = agendaApi.useGetVoteQuery({ agendaName: agenda.name });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {voteItem === undefined ? (
        <TakeVote
          agenda={agenda}
          reloadVote={refetch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <TakeUnvote
          agenda={agenda}
          selectedItem={voteItem.item}
          reloadVote={refetch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};
