import { AgendaOpenSummary } from "../types";
import { Button, Radio, RadioGroup, Flex, Text } from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useMakeToast } from "../function/makeToast";
import { agendaApi } from "../redux/agendaApi";
import { ThemeContext } from "../constants";

export const TakeVote: React.FC<{
  agenda: AgendaOpenSummary;
  reloadVote: () => void;
  isLoading: boolean;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
}> = ({ agenda, reloadVote, isLoading, setIsLoading }) => {
  const makeToast = useMakeToast();
  const [postVote, status] = agendaApi.usePostVoteMutation();
  const theme = useContext(ThemeContext);

  const [radioItem, setRadioItem] = useState(agenda.items[0]);

  const onClickVote = () => {
    setIsLoading(true);
    postVote({ agendaName: agenda.name, item: radioItem });
  };

  useEffect(() => {
    if (status.isSuccess) {
      makeToast({ isSuccess: true, description: "투표가 기록되었습니다" });
      reloadVote();
    } else if (status.isError) {
      makeToast({ isSuccess: false, description: "투표를 실패했습니다" });
    }
    setTimeout(() => setIsLoading(false), 5000);
  }, [status]);

  return (
    <Flex flexDir={"column"} p="5" gap="5">
      <RadioGroup onChange={setRadioItem} value={radioItem}>
        <Flex flexDir={"column"}>
          {agenda.items.map((item) => (
            <Radio value={item} key={item}>
              <Text fontSize={theme.mediumFontSize}>{item}</Text>
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
      <Button colorScheme={"blue"} isDisabled={isLoading} onClick={onClickVote} width="fit-content">
        <Text fontSize={theme.smallFontSize}>투표</Text>
      </Button>
    </Flex>
  );
};
