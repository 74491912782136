import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { bgLightgray } from "../constants";
import { AgendaCreateBody } from "../types";
import { useMakeToast } from "../function/makeToast";
import { agendaApi } from "../redux/agendaApi";

const defaultFormData = {
  name: "",
  items: [],
  isOpen: true,
};

export const AgendaCreatorTab: React.FC<{}> = () => {
  const [formData, setFormData] = useState<AgendaCreateBody>(defaultFormData);
  const [createAgenda, status] = agendaApi.useCreateAgendaMutation();
  const makeToast = useMakeToast();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const onItemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      items: value.split(","),
    });
  };
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData({
      ...formData,
      isOpen: checked,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // validate input
    if (formData.name === "") {
      makeToast({
        isSuccess: false,
        title: "입력 값 오류",
        description: "안건명을 입력해주세요",
      });
      return;
    } else if (formData.items.length === 0) {
      makeToast({
        isSuccess: false,
        title: "입력 값 오류",
        description: "항목을 입력해주세요",
      });
      return;
    }

    // fetch
    createAgenda(formData);
  };

  useEffect(() => {
    if (status.isSuccess) {
      makeToast({ isSuccess: true, description: `${formData.name} 안건이 등록되었습니다` });
    } else if (status.isError) {
      makeToast({ isSuccess: false, description: "안건 등록을 실패했습니다" });
    }
  }, [status]);

  return (
    <Box bgColor={bgLightgray} shadow="md" w="100%">
      <form onSubmit={onSubmit}>
        <FormControl isInvalid={formData.name === ""} p="1rem">
          <FormLabel>안건명</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={onInputChange} />
          <FormErrorMessage>안건명을 입력해주세요</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formData.items.length === 0} p="1rem">
          <FormLabel>항목</FormLabel>
          <Input
            type="text"
            name="items"
            value={formData.items.join(",")}
            onChange={onItemsChange}
          />
          <FormHelperText>각 항목은 쉼표(,)로 구분해주세요</FormHelperText>
          <FormErrorMessage>항목을 입력해주세요</FormErrorMessage>
        </FormControl>
        <FormControl p="1rem">
          <FormLabel>투표 시작 여부</FormLabel>
          <Checkbox
            type="text"
            name="isOpen"
            size="lg"
            checked={formData.isOpen}
            onChange={onCheckboxChange}
          />
        </FormControl>
        <Button type="submit" m="1rem" w="calc(100% - 2rem)">
          등록
        </Button>
      </form>
    </Box>
  );
};
