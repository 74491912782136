import React from "react";
import { Button, List, ListItem } from "@chakra-ui/react";
import { AgendaDetailsView } from "../component/AgendaDetailsView";
import { agendaApi } from "../redux/agendaApi";

export const AgendaDetailsTab: React.FC = () => {
  const { data: agendas, refetch } = agendaApi.useGetAgendaDetailsQuery();
  return (
    <>
      <Button onClick={refetch}>데이터 새로고침</Button>
      <List>
        {agendas?.map((val) => {
          return (
            <ListItem key={val.name} m="1rem" shadow={"md"} rounded="2xl">
              <AgendaDetailsView
                reloadDetails={async () => {
                  refetch();
                }}
                agenda={val}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
