import { Alert, AlertIcon } from "@chakra-ui/react";
import React from "react";

export const LoginPlease: React.FC<{}> = () => {
  return (
    <Alert status="info">
      <AlertIcon />
      로그인 후 이용하세요
    </Alert>
  );
};
