import { AgendaSummaryTab } from "./AgendaSummaryTab";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Center, Text } from "@chakra-ui/react";
import { AgendaDetailsTab } from "./AgendaDetailsTab";
import { AgendaCreatorTab } from "./AgendaCreatorTab";
import { Loading } from "../component/Loading";
import { agendaApi } from "../redux/agendaApi";
import { useContext } from "react";
import { ThemeContext } from "../constants";

export const HomePage = () => {
  const { data: user } = agendaApi.useGetUserDataQuery();
  const theme = useContext(ThemeContext);

  return (
    <Center margin={"1rem"} w={theme.deviceType === "mobile" ? "90%" : "80%"}>
      <Tabs w={"100%"}>
        <TabList>
          <Tab>
            <Text fontSize={theme.largeFontSize}>안건</Text>
          </Tab>
          <Tab hidden={user?.role !== "leader"}>
            <Text fontSize={theme.largeFontSize}>안건 관리</Text>
          </Tab>
          <Tab hidden={user?.role !== "leader"}>
            <Text fontSize={theme.largeFontSize}>안건 등록</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>{user ? <AgendaSummaryTab /> : <Loading />}</TabPanel>

          <TabPanel>{user && user.role === "leader" ? <AgendaDetailsTab /> : <Loading />}</TabPanel>
          <TabPanel>{user && user.role === "leader" ? <AgendaCreatorTab /> : <Loading />}</TabPanel>
        </TabPanels>
      </Tabs>
    </Center>
  );
};
