import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Input, Button, Box } from "@chakra-ui/react";
import { defaultLoginData } from "../types";
import { bgLightgray } from "../constants";
import { agendaApi } from "../redux/agendaApi";
import { useNavigate } from "react-router-dom";
import { useMakeToast } from "../function/makeToast";

export const LoginForm = () => {
  const [formData, setFormData] = useState(defaultLoginData);
  const [postLogin, status] = agendaApi.useLoginMutation();
  const navigate = useNavigate();
  const makeToast = useMakeToast();

  useEffect(() => {
    if (status.isSuccess) {
      navigate("/");
      window.location.reload();
    } else if (status.isError) {
      makeToast({ isSuccess: false, title: "로그인 실패" });
    }
  }, [status, navigate]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.schoolId.length !== 5 || formData.password === "") {
      makeToast({ isSuccess: false, title: "로그인 실패" });
      return;
    }
    postLogin({ logindata: formData });
  };

  return (
    <Box bgColor={bgLightgray} shadow="md" w="100%">
      <form onSubmit={onSubmit}>
        <FormControl p="1rem">
          <FormLabel>id</FormLabel>
          <Input type="text" name="schoolId" value={formData.schoolId} onChange={onInputChange} />
        </FormControl>
        <FormControl p="1rem">
          <FormLabel>password</FormLabel>
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={onInputChange}
          />
        </FormControl>
        <Button type="submit" m="1rem" w="calc(100% - 2rem)">
          Login
        </Button>
      </form>
    </Box>
  );
};
