import { Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { ThemeContext } from "../constants";
import { AgendaCloseSummary } from "../types";

export const AgendaCloseView: React.FC<{ agenda: AgendaCloseSummary }> = ({ agenda }) => {
  const theme = useContext(ThemeContext);

  return (
    <VStack align={"baseline"} p="5">
      {agenda.items.map((item) => (
        <Text key={item.name} fontSize={theme.mediumFontSize}>
          {item.name}: {item.numberOfVoters}명
        </Text>
      ))}
      <Text fontSize={theme.mediumFontSize}>기권: {agenda.numberOfUnvoted}명</Text>
    </VStack>
  );
};
