import React, { useContext, useState } from "react";
import { AgendaSummary } from "../types";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { bgLightblue, bgLightgray, ThemeContext } from "../constants";
import { AgendaOpenView } from "./AgendaOpenView";
import { AgendaCloseView } from "./AgendaCloseView";
import { checkVoter } from "../function/checkVoter";
import { agendaApi } from "../redux/agendaApi";
import { LoginPlease } from "./LoginPlease";

export const AgendaSummaryView: React.FC<{ agenda: AgendaSummary }> = ({ agenda }) => {
  const { data: user } = agendaApi.useGetUserDataQuery();
  const [isShowDetail, setIsShowDetail] = useState(false);
  const theme = useContext(ThemeContext);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsShowDetail(!isShowDetail);
  };

  return user !== undefined ? (
    <Box bgColor={bgLightgray} rounded="2xl">
      <Flex
        align="center"
        onClick={onClick}
        w="100%"
        p="3"
        justifyContent="space-between"
        bgColor={agenda.isOpen ? bgLightblue : bgLightgray}
        roundedTop="2xl"
        roundedBottom={checkVoter(user?.role) ? undefined : "2xl"}
      >
        <Text
          marginEnd={"1rem"}
          marginStart={"1rem"}
          fontSize={theme.largeFontSize}
          fontWeight={theme.boldFontWeight}
        >
          {agenda.name}
        </Text>
        <Center>
          <ChevronDownIcon w="10" h="10" />
        </Center>
      </Flex>

      {isShowDetail &&
        (agenda.isOpen ? (
          checkVoter(user?.role) && <AgendaOpenView agenda={agenda} />
        ) : (
          <AgendaCloseView agenda={agenda} />
        ))}
    </Box>
  ) : (
    <LoginPlease />
  );
};
