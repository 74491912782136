import { useToast } from "@chakra-ui/react";

export interface ToastData {
  isSuccess: boolean;
  title?: string;
  description?: string;
}

export const useMakeToast = () => {
  const makeToast = useToast();
  return ({ isSuccess, title, description }: ToastData) => {
    if (isSuccess) {
      makeToast({
        position: "top",
        title: title ?? "성공",
        description,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      makeToast({
        position: "top",
        title: title ?? "오류",
        description,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
};
