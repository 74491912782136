import React from "react";
import { AgendaSummaryView } from "../component/AgendaSummaryView";
import { List, ListItem } from "@chakra-ui/react";
import { agendaApi } from "../redux/agendaApi";
import { Loading } from "../component/Loading";

export const AgendaSummaryTab: React.FC = () => {
  const { data: agendas } = agendaApi.useGetAgendaSummariesQuery();
  return agendas === undefined ? (
    <Loading />
  ) : (
    <List w="100%">
      {agendas?.map((val) => {
        return (
          <ListItem key={val.name} m="1rem" shadow={"md"} rounded="2xl">
            <AgendaSummaryView agenda={val} />
          </ListItem>
        );
      })}
    </List>
  );
};
