import { AgendaOpenSummary } from "../types";
import { Button, Radio, RadioGroup, Flex, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { agendaApi } from "../redux/agendaApi";
import { useMakeToast } from "../function/makeToast";
import { ThemeContext } from "../constants";

export const TakeUnvote: React.FC<{
  agenda: AgendaOpenSummary;
  selectedItem: string;
  reloadVote: () => void;
  isLoading: boolean;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
}> = ({ agenda, selectedItem, reloadVote, isLoading, setIsLoading }) => {
  const makeToast = useMakeToast();
  const [deleteVote, status] = agendaApi.useDeleteVoteMutation();
  const theme = useContext(ThemeContext);

  const onClickUnvote = async () => {
    setIsLoading(true);
    deleteVote({ agendaName: agenda.name });
  };

  useEffect(() => {
    if (status.isSuccess) {
      makeToast({ isSuccess: true, description: "투표가 취소되었습니다" });
      reloadVote();
    } else if (status.isError) {
      makeToast({ isSuccess: false, description: "투표취소를 실패했습니다" });
    }
    setTimeout(() => setIsLoading(false), 5000);
  }, [status]);

  return (
    <Flex flexDir={"column"} p="5" gap="5">
      <RadioGroup isDisabled value={selectedItem}>
        <Flex flexDir={"column"}>
          {agenda.items.map((item) => {
            return (
              <Radio value={item} key={item}>
                <Text fontSize={theme.mediumFontSize}>{item}</Text>
              </Radio>
            );
          })}
        </Flex>
      </RadioGroup>
      <Button
        colorScheme={"red"}
        isDisabled={isLoading}
        onClick={onClickUnvote}
        width="fit-content"
      >
        <Text fontSize={theme.smallFontSize}>투표 취소</Text>
      </Button>
    </Flex>
  );
};
