import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { agendaApi } from "./agendaApi";
import { combineReducers, Store } from "redux";
import { Persistor } from "redux-persist/es/types";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface PersistantStore extends Store<RootState> {
  __persistor?: Persistor;
}

const appReducer = combineReducers({
  [agendaApi.reducerPath]: agendaApi.reducer,
});
const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STORE") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export const resetStoreAction = () => {
  return { type: "RESET_STORE", payload: null };
};

const persistedReducer = persistReducer(
  {
    key: "root",
    storage: storage,
    whitelist: [agendaApi.reducerPath],
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(agendaApi.middleware),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
