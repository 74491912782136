export interface AgendaOpenSummary {
  name: string;
  items: string[];
  isOpen: true;
}
export interface AgendaCloseSummary {
  name: string;
  items: { name: string; numberOfVoters: number }[];
  numberOfUnvoted: number;
  isOpen: false;
}
export type AgendaSummary = AgendaOpenSummary | AgendaCloseSummary;

export interface AgendaDetails {
  name: string;
  items: { name: string; voters: string[] }[];
  voters: string[];
  unvoted: { schoolId: string; name: string }[];
  isOpen: boolean;
}

export interface AgendaCreateBody {
  name: string;
  items: string[];
  isOpen: boolean;
}

export type Role = "general" | "voter" | "leader";
export interface User {
  isLoggedIn: boolean;
  name?: string;
  sId?: string; // 학번
  schoolId?: string; // 교번, unique identifier
  password?: string;
  role?: Role;
}

export interface typeLoginData {
  schoolId: string;
  password: string;
}

export const defaultLoginData: typeLoginData = {
  schoolId: "",
  password: "",
};
