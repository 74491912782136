import React, { useContext, useEffect } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

import { bgHeaderColor, ThemeContext } from "../constants";
import { Link } from "react-router-dom";
import { agendaApi } from "../redux/agendaApi";
import { resetStoreAction, useAppDispatch } from "../redux";

export const Header: React.FC = () => {
  const [logout, { isSuccess }] = agendaApi.useLogoutMutation();
  const { data: user } = agendaApi.useGetUserDataQuery();
  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetStoreAction());
      window.location.reload();
    }
  }, [isSuccess]);

  return (
    <Flex bgColor={bgHeaderColor} w="100%" minH="2.5rem" align="center">
      <Link to="/">
        <Text fontSize={theme.mediumFontSize} p="1rem">
          대의원회의 투표
        </Text>
      </Link>
      {user === undefined ? (
        <Link to="/login">
          <Button>
            <Text fontSize={theme.smallFontSize}>로그인</Text>
          </Button>
        </Link>
      ) : (
        <Button onClick={() => logout()}>
          <Text fontSize={theme.smallFontSize}>{user?.name}님 로그아웃</Text>
        </Button>
      )}
    </Flex>
  );
};
