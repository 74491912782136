import { Alert, AlertIcon } from "@chakra-ui/react";
import React from "react";

export const Loading: React.FC<{}> = () => {
  return (
    <Alert status="info">
      <AlertIcon />
      로딩중...
    </Alert>
  );
};
