import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../constants";
import { AgendaCreateBody, AgendaDetails, AgendaSummary, typeLoginData, User } from "../types";
import { hash } from "../function/hash";
import axios from "axios";

export const agendaApi = createApi({
  reducerPath: "agendaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/`,
    prepareHeaders: (headers) => {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // vote apis
    getVote: builder.query<{ item: string }, { agendaName: string }>({
      queryFn: async ({ agendaName }) => {
        try {
          const res = await axios.get(`${SERVER_URL}/vote/${agendaName}`, {
            withCredentials: true,
          });
          return { data: res.data };
        } catch {
          return { data: undefined };
        }
      },
    }),
    postVote: builder.mutation<void, { agendaName: string; item: string }>({
      query: ({ agendaName, item }) => ({
        url: `vote/${agendaName}`,
        method: "POST",
        body: { item },
      }),
    }),
    deleteVote: builder.mutation<void, { agendaName: string }>({
      query: ({ agendaName }) => ({
        url: `vote/${agendaName}`,
        method: "DELETE",
      }),
    }),

    // user data apis
    login: builder.mutation<void, { logindata: typeLoginData }>({
      query: ({ logindata }) => ({
        url: `auth/login`,
        method: "POST",
        body: {
          schoolId: logindata.schoolId,
          password: hash(logindata.password),
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: `auth/logout`, method: "POST" }),
    }),
    getUserData: builder.query<User | undefined, void>({
      query: () => `auth/user`,
      transformResponse: (response: User | undefined) => {
        return response
          ? {
              ...response,
              isLoggedIn: true,
            }
          : undefined;
      },
    }),

    // agenda apis
    getAgendaSummaries: builder.query<AgendaSummary[], void>({
      query: () => `agenda/summary`,
      transformResponse: (response: AgendaSummary[]) => response,
    }),
    getAgendaDetails: builder.query<AgendaDetails[], void>({
      query: () => `agenda/details`,
    }),
    putAgendaIsOpen: builder.mutation<void, { agendaName: string; isOpen: boolean }>({
      query: ({ agendaName, isOpen }) => ({
        url: `agenda/${agendaName}`,
        method: "PUT",
        body: { isOpen },
      }),
    }),
    deleteAgenda: builder.mutation<void, { agendaName: string }>({
      query: ({ agendaName }) => ({
        url: `agenda/${agendaName}`,
        method: "DELETE",
      }),
    }),
    createAgenda: builder.mutation<void, AgendaCreateBody>({
      query: (body) => ({
        url: `agenda`,
        method: "POST",
        body,
      }),
    }),
  }),
});
