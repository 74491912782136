import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { bgLightblue, bgLightgray } from "../constants";
import { AgendaDetails } from "../types";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { checkVoter } from "../function/checkVoter";
import { agendaApi } from "../redux/agendaApi";
import { LoginPlease } from "./LoginPlease";
import { useMakeToast } from "../function/makeToast";

export const AgendaDetailsView: React.FC<{
  agenda: AgendaDetails;
  reloadDetails: () => Promise<void>;
}> = ({ agenda, reloadDetails }) => {
  const makeToast = useMakeToast();

  const { data: user } = agendaApi.useGetUserDataQuery();
  const [putAgendaOpen, putStatus] = agendaApi.usePutAgendaIsOpenMutation();
  const [deleteAgenda, deleteStatus] = agendaApi.useDeleteAgendaMutation();

  const [isShowDetail, setIsShowDetail] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsShowDetail((isShowDetail) => !isShowDetail);
  };

  const onClickToggleOpen = async () => {
    putAgendaOpen({ agendaName: agenda.name, isOpen: !agenda.isOpen });
  };
  const onClickDeleteAgenda = async () => {
    deleteAgenda({ agendaName: agenda.name });
  };

  useEffect(() => {
    const desc = !agenda.isOpen ? "투표 시작" : "투표 마감";
    if (putStatus.isSuccess) {
      makeToast({ isSuccess: true, description: `${desc}을 성공했습니다` });
      reloadDetails();
    } else if (putStatus.isError) {
      makeToast({ isSuccess: false, description: `${desc}을 실패했습니다` });
    }
  }, [putStatus]);

  useEffect(() => {
    const desc = "투표 삭제";
    if (deleteStatus.isSuccess) {
      makeToast({ isSuccess: true, description: `${desc}을 성공했습니다` });
      reloadDetails();
    } else if (deleteStatus.isError) {
      makeToast({ isSuccess: false, description: `${desc}을 실패했습니다` });
    }
  }, [deleteStatus]);

  return user !== undefined ? (
    <Box bgColor={bgLightgray} rounded="2xl">
      <Flex
        minH={"4rem"}
        align="center"
        onClick={onClick}
        w="100%"
        justifyContent="space-between"
        bgColor={agenda.isOpen ? bgLightblue : bgLightgray}
        roundedTop="2xl"
        roundedBottom={checkVoter(user?.role) ? undefined : "2xl"}
      >
        <Text marginEnd={"2rem"} marginStart={"1rem"} fontSize="larger" fontWeight="700">
          {agenda.name}
        </Text>
        <Center marginEnd={"2rem"}>
          <ChevronDownIcon w="10" h="10" />
        </Center>
      </Flex>

      {isShowDetail && (
        <Flex p="5" flexDir={"column"} gap="5">
          <Flex justify={"space-between"}>
            <Text>{agenda.isOpen ? "투표 중" : "투표 마감"}</Text>
            <HStack>
              <Button colorScheme={"blue"} onClick={onClickToggleOpen}>
                {agenda.isOpen ? "투표 마감하기" : "투표 시작하기"}
              </Button>
              <Button colorScheme={"red"} onClick={onClickDeleteAgenda}>
                투표 삭제
              </Button>
            </HStack>
          </Flex>
          <VStack align={"baseline"}>
            {agenda.items.map((item) => (
              <Text key={item.name}>
                {item.name}: {item.voters.length}명
              </Text>
            ))}
          </VStack>
          <VStack align={"baseline"}>
            <Text>미투표: {agenda.unvoted.length}명</Text>
            <Text pl="5">
              {agenda.unvoted.map((voter) => `${voter.schoolId} ${voter.name}`).join(", ")}
            </Text>
          </VStack>
        </Flex>
      )}
    </Box>
  ) : (
    <LoginPlease />
  );
};
